import * as React from "react"
import { Helmet } from "react-helmet";
import Layout from '../components/layout'


// markup
const NotFoundPage = () => {
    return (
      <Layout>
        <Helmet>
          <title>Blanka Opekarova</title>
          <meta name="description" content="Blanka Opekarova" />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>
  
        <div className="flex mb-4 flex-wrap">
       <div  className="w-full">
          Stranka nenalezena, neco se ztratilo??
        </div>
        </div>
  
       </Layout>
    )
  }

export default NotFoundPage
